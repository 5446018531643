<template>
    <div class="login">
      <div class="banner"></div>
      <div class="login_form">
        <h2 style="font-size: 44px;letter-spacing: 6px;font-weight: 500">欢迎登录</h2>
        <div style="width: 400px;">
          <t-form :data="formData" label-align="top" :label-width="40">
            <t-form-item label="账号" name="user">
              <t-input size="large" v-model="formData.user"></t-input>
            </t-form-item>
            <t-form-item label="密码" name="pas">
              <t-input size="large" v-model="formData.pas" type="password"></t-input>
            </t-form-item>
            <t-form-item style="padding-top: 8px">
              <t-button @click="user_login" theme="primary" type="submit" size="large" block>登录</t-button>
            </t-form-item>
          </t-form>
        </div>
      </div>
    </div>
</template>

<script setup>
import {reactive} from "vue";
import {login} from "../../api/user";
import {setToken} from "../../utils/auto";
import router from "../../router";
import {MessagePlugin} from "tdesign-vue-next";

const formData = reactive({
  user:'',
  pas:''
})

const user_login = async ()=>{
  let {data,code,msg} = await login({user:formData.user,pas:formData.pas})
  if(code===100 && data.role && data.token){
    MessagePlugin.success('登录成功')
    if(setToken(data)){
      router.push('/')
    }
  }
  console.log(data)
}
</script>

<style scoped>
  .login_form{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .banner{
    width: 50vw;
    min-width: 900px;
    max-height: 1080px;
    height: 100%;
    background: url("http://img.yltest.top/2022/05/20/6287937f3f668.png") no-repeat;
    background-size: 100% 100%;
  }
  .login{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

<style>
.t-form__label--top>label{
  float: left;
}
.login_form .t-button--variant-base.t-button--theme-primary{
  background-color: #42b983;
  border-color: #42b983;
}
.login_form .t-button--variant-base.t-button--theme-primary>div>div{
  background-color: #24b07d !important;
}
</style>
